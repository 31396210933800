import React from "react"

import { InlineLink, BodyText, HighlightText, List, PageContainer } from "../recipes";

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ContactLink from "../components/ContactLink"

const Page = () => (
  <Layout>
    <SEO title="Cakes" />
    <PageContainer>
      <PageContainer.Title text="cakes.title" />
      <BodyText>
        With a few details about your requirements, I can make you the perfect
        cake for your event, party, Saturday night in (I don't judge).
      </BodyText>
      <List>
        <List.Item>
          <List.Text>My cakes come in many forms, but for an idea...</List.Text>
          <List>
            <List.SubItem>
              <List.Text>
                They are 3 layers. They can be made taller if you need.
              </List.Text>
            </List.SubItem>
            <List.SubItem>
              <List.Text>
                They can either be round or square. Square ones feed more
                mouths.
              </List.Text>
            </List.SubItem>
            <List.SubItem>
              <List.Text>
                My serves are generous, don't skimp on your loved ones.
              </List.Text>
            </List.SubItem>
            <List.SubItem>
              <List.Text>
                6 inch round, serves 12.{" "}
                <HighlightText>From ~$140.</HighlightText>
              </List.Text>
            </List.SubItem>
            <List.SubItem>
              <List.Text>
                7 inch round, serves 16.{" "}
                <HighlightText>From ~$165.</HighlightText>
              </List.Text>
            </List.SubItem>
            <List.SubItem>
              <List.Text>
                8 inch round, serves 20.{" "}
                <HighlightText>From ~$190.</HighlightText>
              </List.Text>
            </List.SubItem>
            <List.SubItem>
              <List.Text>
                10 inch round, serves 28-32.{" "}
                <HighlightText>From ~$260.</HighlightText>
              </List.Text>
            </List.SubItem>
            <List.SubItem>
              <List.Text>
                For larger celebrations,{" "}
                <InlineLink to="/contact">drop me a line</InlineLink> and we can talk
                through the options!
              </List.Text>
            </List.SubItem>
          </List>
        </List.Item>
        <List.Item>
          <List.Text>
            Exact cake prices will be quoted as soon as I can, the more notice
            you can give the better.
          </List.Text>
        </List.Item>
        <List.Item>
          <List.Text>
            Let me know your favourite{" "}
            <InlineLink to="/flavours">flavours</InlineLink>.
          </List.Text>
        </List.Item>
        <List.Item>
          <List.Text>
            If you are looking for something more rustic or homely, ask about a
            bundt cake or chiffon cake. Hit up my Instagram for ideas.
          </List.Text>
        </List.Item>
        <List.Item>
          <List.Text>
            If you want, I can match a theme or colour scheme. If you don't have
            one, don't sweat, that's more fun anyway!
          </List.Text>
        </List.Item>
      </List>
      <ContactLink>Please make me cake</ContactLink>
    </PageContainer>
  </Layout>
)

export default Page;
